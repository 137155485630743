<template>
  <input
    class="f-search"
    :value="money"
    @input="$emit('input', getValue($event.target.value))"
    @change="$emit('change', getValue($event.target.value))"
    @focus="indicatorChange = true"
    @blur="indicatorChange = false"
  />
</template>

<script>
export default {
  props: {
    value: [Number, String, Object, Array],
  },
  data: () => {
    return {
      indicatorChange: false, // индикатор редактирования поля
    };
  },
  computed: {
    money: {
      get() {
        return this.indicatorChange ? this.value : Number(this.value).toLocaleString();
      },
    },
    v() {
      if (typeof this.value === "string" || typeof this.value === "number") {
        return this.value;
      }
      if (typeof this.value === "object" && this.value.value != undefined) {
        let r = this.value.value.match(/^%(.*)%$/);
        if (r) {
          return r[1];
        } else {
          return this.value.value;
        }
      }
      return "";
    },
  },
  methods: {
    getValue(v) {
      let value = +v.replace(/\s/g, "");
      return value;
    },
  },
};
</script>
<style lang="scss">
@import "styles.scss";
</style>
