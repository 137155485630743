var render = function render(){var _vm=this,_c=_vm._self._c;return _c('div',{staticClass:"pb-2"},[(_vm.model.autocomplete)?_c('v-autocomplete',{attrs:{"outlined":"","items":_vm.getSortOptions(_vm.model),"item-text":_vm.textField,"item-value":"value","item-disabled":_vm.checkDisabled,"menu-props":"offset-y","value":_vm.value,"label":_vm.$t(_vm.titleValue),"error-messages":_vm.error,"disabled":_vm.disabled,"hide-details":_vm.config.hideDetails || false,"clearable":!_vm.readonly,"readonly":_vm.readonly,"dense":_vm.config.dense || false,"small-chips":_vm.model.chips || false,"multiple":_vm.model.chips || false,"deletable-chips":_vm.model.chips || false,"hide-selected":_vm.model.chips || false},on:{"input":function($event){return _vm.$emit('input', $event)},"keydown":_vm.onKeyDown}}):_c('v-select',{attrs:{"outlined":"","clearable":!_vm.readonly,"items":_vm.getSortOptions(_vm.model),"item-text":_vm.textField,"item-value":"value","item-disabled":_vm.checkDisabled,"item-disabled1":"hidden","value":_vm.value,"menu-props":"offset-y","label":_vm.$t(_vm.titleValue),"error-messages":_vm.error,"disabled":_vm.disabled,"hide-details":_vm.config.hideDetails || false,"readonly":_vm.readonly,"dense":_vm.config.dense || false,"small-chips":_vm.model.chips || false,"multiple":_vm.model.chips || false,"deletable-chips":_vm.model.chips || false,"hide-selected":_vm.model.chips || false},on:{"input":function($event){return _vm.onInput($event)},"keydown":_vm.onKeyDown}}),(_vm.balance || true)?_c('a-form-view',{attrs:{"value":_vm.balance,"model":[
      { name: 'date_doc', title: 'Начало периода', type: 'datetime' },
      { name: 'value_start', title: 'Сумма периода', type: 'number' },
      { name: 'value_in', title: 'Приход за период', type: 'number' },
      { name: 'value_out', title: 'Расход за период', type: 'number' },
      { name: 'day_value_start', title: 'Начало дня', type: 'number', color: 'green' },
      { name: 'day_value_in', title: 'Приход за день', type: 'number', color: 'green' },
      { name: 'day_value_out', title: 'Расход за день', type: 'number', color: 'green' },
      { name: 'value_end', title: 'Остаток в кассе', type: 'number' },
      { name: 'value_doc', title: 'После проводки', type: 'number' },
    ],"config":{
      dense: true,
      hideNull: true,
      size: 28,
    }},nativeOn:{"click":function($event){return _vm.getBalance()}}}):_vm._e()],1)
}
var staticRenderFns = []

export { render, staticRenderFns }