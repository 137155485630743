<template>
  <div class="d-flex" style="position: relative">
    <v-dialog ref="dialog" v-model="showPicker" :return-value="date" persistent width="290px" :disabled="readonly">
      <template v-slot:activator="{ on, attrs }">
        <v-text-field
          outlined
          :value="text"
          :label="model.title"
          :error-messages="error"
          :disabled="disabled"
          :hide-details="config.hideDetails || false"
          readonly
          persistent-placeholder
          placeholder="Не указано"
          :clearable="!!value && !readonly"
          :dense="config.dense || false"
          :append-icon="!value ? 'mdi-calendar' : ''"
          @click.prevent="openDialog"
          @click:append.prevent="openDialog"
          @click:clear="
            $emit('input', null);
            initValue = null;
          "
        >
        </v-text-field>
      </template>
      <v-date-picker
        v-if="showPicker"
        :disabled="readonly"
        v-model="d"
        @dblclick:date="
          //$emit('input', d);
          //initValue = d;
          setDate();
          $refs.dialog.save();
        "
        full-width
      >
        <v-spacer></v-spacer>
        <v-btn
          @click="
            //$emit('input', initValue);
            showPicker = false
          "
        >
          Закрыть
        </v-btn>
        <v-btn
          v-if="!readonly"
          color="primary"
          @click="
            $emit('input', d);
            initValue = d;
            $refs.dialog.save();
          "
        >
          Записать
        </v-btn>
      </v-date-picker>
    </v-dialog>
  </div>
</template>

<script>
export default {
  props: {
    value: String,
    model: Object,
    config: { type: Object, default: {} },
    disabled: {
      type: Boolean,
      default: false,
    },
    error: {
      type: String,
      default: "",
    },
  },
  data() {
    return {
      text1: null,
      d: null,
      initValue: this.value,
      showPicker: false,
      date: null,
    };
  },
  watch: {
    value1(v) {
      console.log("watch value", v);
      this.setText();
    },
    showPicker(v) {
      if (v) {
        this.selectDate(this.value);
      }
    },
  },
  created() {
    //this.setText();
  },
  computed: {
    readonly() {
      if (this.config?.readonly || this.model?.readonly) return true;
      return false;
    },
    text: {
      get() {
        //console.log("vvvv", this.value, this.value ? new Date(this.value).date : "Не указано");
        return this.setText();
      },
      set(v) {
        this.$emit("input", null);
      },
    },
    date1: {
      get: function () {
        console.log("get date");
        if (!this.value) {
          this.$emit("input", null);
          this.selectDate();
          return new Date().date;
        }
        this.selectDate(this.value);
        return new Date(this.value).date;
      },
      set: function (v) {
        console.log("set date");
        this.selectDate(v);
        // this.text = new Date(v).date;
        // this.$emit("input", new Date(v).date);
        // this.$emit("commit", v);
      },
    },
  },
  methods: {
    selectDate(d) {
      if (!d) this.d = new Date().date;
      else this.d = new Date(d).date;
    },
    setDate() {
      this.$emit("input", this.d);
    },
    openDialog() {
      if (this.disabled) return;
      this.showPicker = true;
    },

    setText() {
      return this.value ? new Date(this.value).date : "";
      this.text = this.value ? new Date(this.value).date : "";
    },
  },
};
</script>
