<template>
  <div class="notification-container">
    <div class="notification-header">
      <span>Уведомления</span>
      <div class="notification-controls">
        <label class="px-3"><input type="checkbox" v-model="onlyViewed" /> Только непрочитанные</label>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="doClose()" icon small v-bind="attrs" v-on="on" class="ml-3">
              <v-icon color=" primary" dark small> fa fa-check </v-icon>
            </v-btn>
          </template>
          <span>Отметить все прочитанным</span>
        </v-tooltip>
        <v-tooltip bottom>
          <template v-slot:activator="{ on, attrs }">
            <v-btn @click="$emit('close')" icon small v-bind="attrs" v-on="on" class="ml-3">
              <v-icon color=" primary" small> fa fa-times </v-icon>
            </v-btn>
          </template>
          <span>Закрыть</span>
        </v-tooltip>
      </div>
    </div>
    <div class="notification-list" style="overflow-y: auto; max-height: 80vh">
      <div v-if="loading" style="text-align: center; margin: 15px">
        <v-progress-circular indeterminate color="primary"></v-progress-circular>
      </div>
      <div v-for="item of itemsList" :key="item.id" class="notification-card">
        <div class="notification-header-content">
          <span class="notification-date">{{ formattedDate(item) }}</span>
          <div class="notification-actions">
            <button @click="doClose(item)" class="text-gray-400 hover:text-gray-600"><i class="fa fa-times"></i></button>
          </div>
        </div>
        <div class="notification-body" :class="{ unread: item.status != 3 }" @click="clickAction(item)">
          <i v-if="item.action" class="fas fa-external-link-alt mr-2"></i>
          {{ item.name }}
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data: function () {
    return {
      count: 0,
      api: "/mechti/events",
      items: [],
      onlyViewed: true,
      loading: false,
    };
  },
  created() {
    this.fitchData();
  },
  computed: {
    itemsList() {
      return this.items.filter((i) => {
        return i.status == 1 || !this.onlyViewed;
      });
    },
  },
  watch: {
    onlyViewed(v) {
      this.fitchData();
    },
  },
  methods: {
    clickAction(el) {
      if (el.target_name == "ChangeLogModel") {
        this.$emit("show", el);
        this.doClose(el);
        return;
      }
      if (el.action) window.open(el.action, "_blank");
      this.doClose(el);
    },
    formattedDate(el) {
      return new Date(el.eventon).toLocaleString();
    },
    async fitchData() {
      this.loading = true;
      const params = { filters: { status: this.onlyViewed == true ? 1 : [1, 3] }, sort: [{ key: "eventon", order: "desc" }] };
      let resp = await this.$axios.get(this.api, { params });
      this.loading = !true;
      this.items = resp.data.data;
    },
    async doClose(el = {}) {
      let data = { all: true };
      if (el?.id) data = { id: el.id };
      let resp = await this.$axios.post("/mechti/events/viewed", data);
      el.status = 3;
      this.$emit("reCount");
    },
  },
};
</script>

<style lang="scss" scoped>
.notification-container {
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  border: 1px solid #ccc;
  border-radius: 5px;
  padding: 10px;
}
.notification-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  font-size: 14px;
  font-weight: bold;
  padding-bottom: 10px;
  border-bottom: 1px solid #ddd;
}
.notification-header label {
  font-size: 12px;
  color: #666;
  margin-left: 10px;
}
.notification-controls {
  justify-content: space-between;
  display: flex;
  align-items: center;
}
.notification-controls button {
  border: none;
  background: none;
  cursor: pointer;
  font-size: 14px;
  color: #0073e6;
  display: flex;
  align-items: center;
}

.notification-card {
  margin: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  border: 1px solid #ccc;
  border-radius: 5px;
  font-family: Arial, sans-serif;
  background-color: #f9f9f9;
  position: relative;
}
.notification-card1:last-child {
  border-bottom: none;
}
.notification-header-content {
  display: flex;
  justify-content: space-between;
  font-size: 12px;
  color: #666;
}
.notification-actions button {
  border: none;
  background: none;
  cursor: pointer;
  color: #666;
  font-size: 14px;
}
.notification-body {
  margin-top: 5px;
  font-size: 14px;
  color: #333;
}
.notification-body.unread {
  font-weight: bold;
}
</style>
