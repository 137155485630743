<template>
  <div v-if="$root.profile.role == 1000">
    <dialog-edit2 :value="value" @input="$emit('input')" :maxWidth="'500px'">
      <template v-slot:title> RAW edit </template>
      {{ { api, id } }}
      <v-row class="text-left">
        <v-col cols="12">
          <TreeBlock v-if="getAccess('suData')" @change="onChange($event)" @remove="onRemove($event)" :value="data" />
        </v-col>
      </v-row>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <a-btn-delete @click="del()" :title="'Удалить'" />
        <a-btn-save @click="post()" :title="'Сохранить'" />
        <v-btn @click="$emit('input')">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>
<script>
import { getAccess } from "@/components/mixings";
export default {
  mixins: [getAccess],

  components: {
    TreeBlock: () => import("@/views/tree/treeBlock"),
  },
  props: {
    value: Boolean,
    activate: Boolean,
    api: String,
    id: Number,
    dataEdit: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {
      // Parameters that change depending on the type of dialogue
      title: undefined,
      message: undefined, // Main text content
      data: {},
      show: false,
    };
  },
  created() {
    window.addEventListener("keydown", this.pressButtonEvent);
  },
  destroyed() {
    window.removeEventListener("keydown", this.pressButtonEvent);
  },
  watch: {
    value(v) {
      this.show = v === true;
      console.log("value(v)", v, this.show);
    },
  },
  methods: {
    pressButtonEvent(e) {
      if (this.activate)
        if (e.keyCode == 114 && e.ctrlKey) {
          e.preventDefault();
          this.data = this.dataEdit;
          // this.value = true;
          //this.show = true;
          this.$emit("show");
          return false;
        }
    },

    onChange(e) {
      console.log("onChange:", e);
      this.data[e.i] = e.v;
    },
    onRemove(e) {
      console.log("onRemove:", e);
      this.data[e.i] = e.v;
    },
    async post() {
      if (!this.api || !this.id) return;
      let response = await this.$axios.post(this.api, this.data);
      let status = response.data.status == "ok";
      if (status) {
        Object.assign(this.data, response.data.data);
        this.$root.$emit("show-info", {
          text: "Данные записаны",
        });
      }
      console.log("response", response);
    },
    async del() {
      if (!this.api || !this.id) return;
      const response = await this.$axios({
        method: "delete",
        url: this.api + "/" + this.id,
        data: {},
        params: {},
      });
      console.log("response", response);
      this.$emit("input");
    },
  },
};
</script>

<style scoped></style>
