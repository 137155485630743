<template>
  <div :class="getClass" :style="style">
    <v-badge v-if="badge" :color="baggeColor" inline dot :value="badge"></v-badge>

    <span>
      {{ value }}
    </span>
    <span><a-view-action-link v-if="model.link" :data="model.link" :values="values" /></span>
  </div>
</template>

<script>
import { modelOptions } from "./../../../mixings";
export default {
  mixins: [modelOptions],
  props: {
    value: [String, Number],
    values: {
      type: Object,
      default: () => {
        return {};
      },
    },
    model: {
      type: Object,
      default: () => {
        return {};
      },
    },
  },
  data() {
    return {};
  },
  computed: {
    badge() {
      return this.model?.badge?.field && this.values[this.model.badge.field];
    },
    baggeColor() {
      if (this.model.hasOwnProperty("badge") && this.model?.badge?.field) {
        let text = this.getElementByValue(this.values[this.model.badge.field], this.model);
        console.log("tttt", text);
        return text?.bgColor;
      }
      return false;
    },
    link() {
      return this.model?.link;
    },
    textColor() {
      if (!this.model?.textColor) return "";
      //this.badge = true;
      return this.model?.textColor(this.values);
    },
    style() {
      let style = "";
      if (this.model?.style) style = this.model?.style;
      return style;
    },

    getClass() {
      let classStr = "";
      if (this.model?.class) classStr = this.model?.class;
      if (this.textColor) classStr += " red--text";
      return classStr;
    },
  },
};
</script>
