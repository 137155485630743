<template>
  <v-row>
    <v-col
      v-if="el.type && el.hidden !== true"
      v-for="(el, i) in model"
      :key="i"
      cols="12"
      class="pb-0 pt-1 mb-2 px-1"
      :sm="getSize(el)"
      :class="{ 'active-field': activeField === i }"
      style="position: relative"
    >
      <component
        :is="'a-input-' + el.type"
        :model="el"
        :values="data"
        :disabled="el.disabled || false"
        :value="getValue(el)"
        :config="config"
        :readonly="readonly"
        @rating="onRating($event, el)"
        @input="onInput($event, el)"
        @focus="setActiveField(el, i)"
        @blur="clearActiveField(i)"
        @fieldData="$emit('fieldData', { name: el.name, value: $event })"
        @selected="$emit('selected', { name: el.name, value: $event })"
        @commit="
          $emit('commit', {
            event: $event,
            el,
          })
        "
        :error="getError(el)"
      />
      <v-tooltip bottom v-if="el.hint && showHint">
        <template v-slot:activator="{ on, attrs }">
          <v-icon v-bind="attrs" v-on="on" class="tooltip">mdi-help-circle</v-icon>
        </template>
        <span v-html="getHint(el.hint)"></span>
      </v-tooltip>
    </v-col>
  </v-row>
</template>

<script>
export default {
  props: {
    value: Object,
    model: Array,
    errors: Object,
    config: {
      type: Object,
      default: () => {
        return { dense: true, hideDetails: false };
      },
    },
    readonly: { type: Boolean, default: false },
  },
  data() {
    return {
      activeField: null, // Индекс активного поля
    };
  },
  computed: {
    showHint() {
      return this.$root.showHint;
    },
    data: {
      set(v) {
        this.$emit("input", v);
      },
      get() {
        return this.value;
      },
    },
    rating: {
      set(v) {
        //this.$emit("input", v);
      },
      get() {
        let res = this.value.rating || {};
        return res;
      },
    },
  },

  methods: {
    getHint(h = "") {
      return JSON.parse(JSON.stringify(h).replace(/\\n/g, "<br>"));
    },
    setActiveField(el, i) {
      console.log("setActiveField", el.name, el?.strech);
      if (el.strech) this.activeField = i;
    },
    clearActiveField(i) {
      if (this.activeField == i) this.activeField = null;
    },
    getSize(el) {
      return el.size || 12;
    },
    getValue(el) {
      if (el.name.indexOf(".") !== -1) {
        let field = el.name.split(".")[0];
        let name = el.name.split(".")[1];
        return this.data?.[field]?.[name];
      }
      return el.json ? this.data.data[el.name] : this.data[el.name];
    },
    getError(el) {
      if (el.calculated === true) {
        let err = [];
        if (el.errorFrom)
          for (let e of el.errorFrom) {
            if (this.errors?.[e])
              err.push(
                this.model.find(m => {
                  return m.name == e;
                })?.title +
                  ": " +
                  this.errors?.[e],
              );
          }
        return err.join(" , ");
      }

      if (el.type == "ref") return this.errors[el.name + "_id"];
      return this.errors[el.name];
    },
    async onInput(e, el) {
      let parent = "";

      if (el.json) {
        let field = el?.jsonField || "data";
        let data = JSON.parse(JSON.stringify(this.data[field]));
        data[el.name] = e;
        this.data[field] = data;
      } else if (el.type == "filesdata" || el.type == "filesdatavideo") {
        let del = JSON.parse(JSON.stringify(this.data["filesRemove"] || []));
        let add = JSON.parse(JSON.stringify(this.data["filesAdd"] || []));
        let d = JSON.parse(JSON.stringify(this.data["filesData"] || []));
        for (const file of e) {
          if (file.type == "add") {
            d.push(file);
            add.push(file);
          }
          if (file.type == "del") {
            d = d.filter(f => {
              return f.upload_id !== file.upload_id;
            });
            if (file?.id) {
              del.push({ upload_id: file.upload_id });
            } else {
              add = add.filter(f => {
                return f.upload_id !== file.upload_id;
              });
            }
          }
        }
        this.data[el.name] = e.filter(f => {
          return f.type !== "del";
        });
        this.data["filesData"] = d;
        this.data["filesAdd"] = add;
        this.data["filesRemove"] = del;
      } else if (el.name.indexOf(".") !== -1) {
        let field = el.name.split(".")[0];
        parent = field;
        let name = el.name.split(".")[1];
        let data = JSON.parse(JSON.stringify(this.data[field]));
        data[name] = e;
        this.data[field] = data;
      } else if (Array.isArray(e)) {
        this.data[el.name] = e;
      } else if (typeof e === "object" && e !== null && el?.return !== "object") {
        if (this.data[el.name] !== null) {
          Object.assign(this.data, e);
        } else {
          this.data[el.name] = e;
        }
        //Object.assign(this.data, e);
      } else {
        this.data[el.name] = e;
      }
      if (el.calculated === true) {
        let err = [];
        if (el.errorFrom)
          for (let e of el.errorFrom) {
            this.$emit("validate", e);
          }
      }
      this.$emit("validate", el.name);
    },
    onRating(e, el) {
      this.$emit("rating", { name: el.name, value: e });
    },
  },
};
</script>
<style scoped>
.active-field {
  transition: all 0.3s ease;
  flex: 0 0 90%; /* Активное поле занимает 90% */
  max-width: 90%;
}
.tooltip {
  position: absolute;
  top: -5px;
  right: 5px;
  z-index: 1000;
}
.v-col {
  transition: all 0.3s ease;
}
</style>
