/** @format */

import cloneDeep from 'lodash/cloneDeep';

Array.prototype.multiSort = function (fields) {
  if (typeof fields == 'string') fields = fields.split(',');
  const fieldSorter = function (fields) {
    return function (a, b) {
      return fields
        .map(function (o) {
          var dir = 1;
          if (o[0] === '-') {
            dir = -1;
            o = o.substring(1);
          }
          if (a[o] > b[o]) return dir;
          if (a[o] < b[o]) return -dir;
          return 0;
        })
        .reduce(function firstNonZeroValue(p, n) {
          return p ? p : n;
        }, 0);
    };
  };
  return this.sort(fieldSorter(fields));
};
Array.prototype.copy = function () {
  const res = cloneDeep(this);
  return res;
  return JSON.parse(JSON.stringify(this));
};
