<template>
  <div>
    <a-form-modelCols
      v-model="data"
      class="pb-3 mx-1 pt-2"
      :model="getFormModel(m.config.agentObjects.fieldsForm.split(','))"
      :values="data"
      :errors="errors"
      :config="{ dense: true, readonly, hideDetails: 'auto' }"
      @validate="
        validate($event);
        doWatch($event);
      "
    />
  </div>
</template>

<script>
import { getForm, submitForm, removeEl, getAccess, genModel } from "@/components/mixings";

export default {
  components: {},
  mixins: [getForm, submitForm, removeEl, getAccess, genModel],
  props: {
    value: Boolean,
    id: Number,
    m: Object,
    configForm: { type: Object, default: () => {} },
    hasData: { type: Boolean, default: false },
    initData: { type: Object, default: () => {} },
    opt: { type: Object, default: () => {} },

    params: { type: Object, default: () => {} },
  },
  data() {
    return {
      model: [],
      idEdit: 0,
      suEditShow: false,
      removeDialogShow: false,
      type: null,
      loaded: false,
      watchModel: null,
      options: [],
      fieldData: {},
    };
  },
  computed: {
    config() {
      if (this.configForm?.head) return this.configForm.head;
      const type = this.configForm?.type || "default";
      return JSON.parse(JSON.stringify(this.m?.config?.[type] || {}));
    },
    api() {
      return this.configForm?.api || this.m?.api || null;
    },
    title() {
      return this.configForm?.title || this.m?.title || "";
    },

    canDelete() {
      return this.opt?.delete === true;
    },
    readonly() {
      if (this.opt?.readonly === true) return true;
      if (this.params?.func?.readonly) {
        try {
          return eval(this.params.func.readonly)(this.data);
        } catch (error) {}
      }
      return false;
    },
    removeTitle() {
      return this.m?.removeTitle || "Вы действительно хотите удалить это?";
    },
  },
  created() {
    this.loaded = false;
    this.updateData(this.id);
    if (!this.id) {
      this.loaded = true; ///  this.type = 1;
      //  this.data.status = 0;
    }
  },
  watch: {
    id() {
      this.updateData(this.id);
    },
  },
  methods: {
    getTitle() {
      //  {{ id ? title : "Новый элемент '" + title + "'" }}
      let res = "";
      if (this.id) res = this.title + (this.readonly ? " [Просмотр]" : " [Редактирование]");
      else res = this.title + " [Создание]";
      return res;
    },
    _submit() {
      if (this.readonly) return;
      if (this.configForm?.submitType == "emit") {
        this.$emit("submit", this.data);
        return;
      }
      const fields = this.configForm.head?.fieldsPost || "";
      this.submit(false, fields);
    },
    postData() {},
    setFieldData___(e) {
      if (!e) return;
      if (this.watchModel) {
        console.log("watchModel");
        for (const key in this.watchModel) {
          if (key == e.name) {
            let data = e.value;
            let v = this.watchModel[key];
            for (const field in v) {
              if (this.data.hasOwnProperty(field) || true) {
                if (v[field].type === "field") {
                  this.setData(field, data?.[v[field].value]);
                } else if (v[field].type === "value") {
                  this.setData(field, v[field].value);
                } else {
                  console.log("не известный тип сопоставления данных " + v[field].type, this.watchModel);
                }
              } else {
              }
            }
          }
        }
      }
    },

    afterFetchData(r) {
      let t = 0;
      if (this.m?.config?.watch) {
        t = this.data?.[this.m.config.watch] || 0;
      }
      this.getModel(t);
      this.loaded = true;
    },
    doWatch(e) {
      // console.log({ e, wathc: this.m?.config?.watch, value: this.data?.[e] });
      if (e == this.m?.config?.watch) this.getModel(this.data?.[e]);
      //console.log("doWatch", e, this.config, this.model);
    },
    getModel(t) {
      let config = JSON.parse(JSON.stringify(this.config));
      if (t || t === 0) config = JSON.parse(JSON.stringify(this.m?.config?.[t] || this.config));
      //console.log("get model", t, config);
      if (this.data.status == 2) config.fieldsRO = config.fields;
      this.watchModel = config?.watch || null;
      let model = this.calcModelConfig(config);
      if (true)
        model.forEach((el) => {
          if (el.hiddenCreate && !this.data?.id) {
            el.hidden = true;
          }
        });
      this.fillFormFromModel(model);
      this.model = model;
      this.inputData(this.initData);
    },
    afterSave(data, status) {
      this.isChanged = false;
      if (status) {
        if (!this.opt?.closeAfterSave === false) this.$emit("input");
      }
      if (this.opt?.closeAfterSave === true) this.$emit("input");
      let id = data.id;
      if (!this.id && this.m?.routeName) {
        if (this.opt?.newWindow === true) {
          const routeData = this.$router.resolve({ name: this.m.routeName, params: { id } });
          window.open(routeData.href, "_blank");
        } else this.$router.push({ name: this.m.routeName, params: { id } });
      }
    },
    doWatch(e) {
      // console.log({ e, wathc: this.m?.config?.watch, value: this.data?.[e] });
      if (e == this.m?.config?.watch) this.getModel(this.data?.[e]);
      //console.log("doWatch", e, this.config, this.model);
    },
    inputData(d = {}) {
      if (d) {
        console.log("input data", d);
        for (const name in d) {
          let m;
          if ((m = this.model.find((m) => m.name == name))) {
            if (this.opt?.fieldsEditable == "*") {
            } else if (!(this.opt.fieldsEditable && this.opt.fieldsEditable.includes(name))) m.readonly = true;
          }
          this.$set(this.data, name, d[name]);
          this.$set(this.errors, name, null);
        }
      }
    },
    async _remove(api, id) {
      if (!api || !id) return;

      await this.remove(api, id);
      this.$emit("deleted");
      this.$emit("input");
    },
  },
};
</script>
