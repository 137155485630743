<template>
  <div>{{ v }}</div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
  },

  computed: {
    v() {
      if (!this.value) return "";
      const d = new Date(this.value);

      return d.toLocaleDateString("ru-RU");
    },
  },
};
</script>
