<template>
  <div>
    <v-badge :color="color" dot :value="badge">{{ v }}</v-badge>
  </div>
</template>

<script>
export default {
  props: {
    value: [String, Number],
    values: {
      type: Object,
      default: () => {
        return {};
      },
    },
    model: Object,
  },
  data() {
    return {
      badge: false,
      color: "",
    };
  },
  computed: {
    v() {
      if (!this.value) return "";
      const d = new Date(this.value);
      this.badge = false;
      if (this?.model?.status && this.model.status.includes(this.values?.status)) {
        this.badge = 0;
        return d.toLocaleDateString("ru-RU");
      }
      if (d <= new Date()) {
        this.badge = 1;
        this.color = (d - new Date()) / (1000 * 60 * 60 * 24) < -1 ? "red" : "yellow";
      }
      return d.toLocaleDateString("ru-RU");
    },
  },
};
</script>
