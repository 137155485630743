<template>
  <div>
    <dialog-edit2 :value="isShow" @input="clickClose" :makeFocus="false">
      <template v-slot:title> {{ source.title || title }} </template>
      <v-card-text style="height: 70vh; overflow: auto" class="pa-0">
        <a-table-f-api
          v-if="type == 'api'"
          ref="table"
          :api="url"
          :model="model"
          :useQuery="false"
          :defaults="{
            sort: { key: 'id', order: 'DESC' },
            filters: filter,
            paramName: tableParamName,
          }"
          @click="clickRow"
        >
        </a-table-f-api>
        <a-table-f-data2
          v-if="type == 'data'"
          ref="table"
          :dataTable="data"
          :model="model"
          :searchable="false"
          :useQuery="false"
          :defaults="{
            sort: { key: 'id', order: 'DESC' },
            filters: filter,
            paramName: tableParamName,
          }"
          @click="clickRow"
        >
        </a-table-f-data2>
      </v-card-text>
      <template v-slot:actions>
        <v-spacer></v-spacer>
        <v-btn @click="clickClose()">Закрыть</v-btn>
      </template>
    </dialog-edit2>
  </div>
</template>

<script>
import { getAccess, genModel } from "@/components/mixings";

export default {
  components: {},
  mixins: [getAccess, genModel],
  props: {
    opts: { type: Object, default: () => {} },
    title_: String,
    value: Boolean,
    source_: { type: Object, default: () => {} },
    mode: { type: String, default: "sync" },
  },
  data() {
    return {
      data: [],
      title: null,
      isShow: false,
      source: {},
    };
  },
  computed: {
    m() {
      if (!this.source.model) return {};
      return JSON.parse(JSON.stringify(this.$store.getters["config/get"].models[this.source.model]));
    },
    model() {
      //console.log("get model doc", this.source);
      const f = this.source?.fields || this.source?.docFields || "";
      let fields = f.split(",") || [];
      let model = this.getModelList(this.m);
      model = model.filter((el) => {
        return fields.includes(el.name);
      });
      model.forEach((m) => {
        m.hidden = false;
      });
      return model;
    },
    url() {
      return this.source.api;
    },
    type() {
      if (typeof this.source.api === "string") return "api";

      if (typeof this.source.api === "object" && this.source.api.type == "post") {
        this.getListData();
        return "data";
      }
      return "";
    },
    filter() {
      return this.source?.filter || {};
    },
    tableParamName() {
      return this.source?.tableParamName || this.m?.tableParamName || "";
    },
  },
  created() {
    if (this.mode == "sync") this.init(this.opts);
  },
  watch: {
    value(v) {
      if (this.mode == "sync") {
        this.isShow = v;
      }
    },
  },
  methods: {
    init(opts) {
      this.title = opts?.title;
      this.source = opts?.source || {};
    },
    show(opts = {}) {
      console.log("show", opts);
      this.resetForm();
      this.init(opts);
      // Once we set our config, we tell the popup modal to open
      this.isShow = true;
      // Return promise so the caller can get results
      return new Promise((resolve, reject) => {
        this.resolvePromise = resolve;
        this.rejectPromise = reject;
      });
    },
    resetForm() {
      this.source = {};
      this.isShow = false;
      this.title = null;
      this.loaded = false;
    },
    async getListData() {
      this.data = [];
      let api = this.source.api;
      if (!api) return;
      let type = "get";
      let data = {};
      if (typeof api === "object") {
        type = api.type || type;
        if (api.data) {
          data.name = api.data?.name;
          data.params = {};
          for (const key in api.data.params) {
            console.log("key is func", key, typeof api.data.params[key]);

            // if (typeof api.data.params[key] === "function") {
            if (typeof api.data.params[key] === "string" && api.data.params[key].includes("function(d)")) {
              let func = eval(api.data.params[key]);
              console.log("key is func", key);
              data.params[key] = func(this.values) || 0;
            } else data.params[key] = api.data.params[key];
          }
        }

        //data = api.data || null;
        api = api.api;
      }
      let response = type == "get" ? await this.$axios.get(api, { params }) : await this.$axios.post(api, data);
      let listData = response.data.data;
      console.log("listData", listData);
      this.data = [...listData];
    },
    clickRow(d) {
      if (this.mode == "sync") {
        this.$emit("select", d.row);
        this.$emit("input");
      } else {
        this.isShow = false;
        this.resolvePromise(d.row);
      }
    },
    clickClose() {
      if (this.mode == "sync") {
        this.$emit("input");
      } else {
        this.isShow = false;
        this.resolvePromise(false);
      }
    },
  },
};
</script>
