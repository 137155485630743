/** @format */

import routes from '@/config/routes';

const dirs = {
  data() {
    return {
      //_dirs: {},
      __dirs: {},
      hasInitDir: false,
      dirState_: {},
      dirs: {},
      dirs_: {},
      dirApi: { job: '/accounting/dir/job/select' },
    };
  },
  created() {
    this.__dirs = {};

    this.initDirs();
  },
  computed: {},
  methods: {
    callDir(model) {
      for (let i = 0; i < model.length; i++) {
        let name = model[i];
        if (this.dirs?.[name]) {
        }
      }
    },
    initDirs() {
      if (this.hasInitDir) return;
      const model = [
        'job',
        'user',
        'client',
        'object',
        'office',
        'storage',
        'metro',
        'employee',
        'department',
        'investor',
        'category',
        'vendor',
        'service',
        'cashbox',
        'objectProps',
        'operationTable',
        'roles',
        'routes',
        'leadsGroup',
        'leadsTask',
        'adsSubAgent',
        'adsContact',
        'residential_complex',
        // 'good',
      ];
      if (!this.hasInitDir) {
        this.hasInitDir = true;
        for (let i = 0; i < model.length; i++) {
          let name = model[i];
          this.$set(this.dirState_, name, 0);
          //this.$set(this.dirs, name, []);
          Object.defineProperty(this.dirs, name, {
            get: () => {
              let res = this.dirs_?.[name] || [];
              if (!res?.length) this._fitchDirs(name);
              return [...res];
            },
          });
        }
      }
      //this.callDir(model);
    },
    async _fitchDirs(data) {
      if (this.dirState_?.[data]) return;
      if (!this.$root?.profile?.role) return;
      this.dirState_[data]++;
      if (data == 'roles') {
        let r = this.$store.getters['config/get'].userRoles;
        let names = this.$store.getters['config/get'].config?.dictionary?.roles;
        let d = [];
        for (const key in r) {
          if (Object.hasOwnProperty.call(r, key)) {
            const id = parseInt(r[key]);
            let text = names[id] ? names[id] : key;
            const row = { id, value: id, text, origin: key };
            d.push(row);
          }
        }
        //console.log('dir ', data, d);
        if (d.length) {
          this._setDir(data, d);
        } else {
          this.dirState_[data] = 0;
          return;
        }
      }
      if (data == 'routes') {
        let d = routes.routes.map((route) => ({
          value: route.name,
          text: route?.meta?.title ? route?.meta?.title : null,
        }));
        d = d.filter((e) => e.text && !e.value.includes('_'));
        this._setDir(data, d);
      }
      if (data == 'operationTable') {
        let name = 'operationTable';
        let d = [];
        let obj = this.$store.getters['config/get'].accounting?.[name] || {};
        let operations = this.$store.getters['config/get'].config?.dictionary?.operations;
        for (const key in obj) {
          if (Object.hasOwnProperty.call(obj, key)) {
            const el = obj[key];
            let text = operations[key] ? operations[key].name : el.name;
            const row = { id: parseInt(key), value: parseInt(key), text };
            for (const k in el) {
              row[k] = el[k];
            }
            d.push(row);
          }
        }
        if (d.length) {
          this._setDir(data, d);
        } else {
          this.dirState_[data] = 0;
          return;
        }
      }
      if (this.dirApi?.[data]) {
        const api = this.dirApi[data];
        this.$axios.get(api).then((resp) => {
          if (resp.data.status === 'ok') {
            let res = resp.data.data;
            this._setDir(data, res);
          }
        });
      }
      if (data == 'adsSubAgent')
        this.$axios.post('/report_sql', { name: 'getAdsSubAgentList' }).then((resp) => {
          if (resp.data.status === 'ok') {
            let res = resp.data.data;
            this._setDir(data, res);
          }
        });
      if (data == 'residential_complex')
        this.$axios.get('/mechti/residential_complex/select').then((resp) => {
          if (resp.data.status === 'ok') {
            let res = resp.data.data;
            this._setDir(data, res);
          }
        });
      if (data == 'adsContact')
        this.$axios.post('/report_sql', { name: 'getAdsContactList' }).then((resp) => {
          if (resp.data.status === 'ok') {
            let res = resp.data.data;
            this._setDir(data, res);
          }
        });
      if (data == 'leadsTask')
        this.$axios.get('/mechti/leads_task_template').then((resp) => {
          let res = [];
          if (resp.data.status === 'ok') {
            resp.data.data.forEach((d) => {
              let r = d;
              r['value'] = d.id;
              r['text'] = d.name;
              r['action_text'] = d?.data?.action_text || d.name;
              res.push(r);
            });
            this._setDir(data, res);
          }
        });
      if (data == 'leadsGroup')
        this.$axios.get('/mechti/leads_status_group', { params: { sort: { key: 'name', order: 'ASC' } } }).then((resp) => {
          //let res = [{ value: 0, text: 'Входящие', status: 1 }];
          let res = [];
          if (resp.data.status === 'ok') {
            resp.data.data.forEach((d) => {
              let r = d;
              r['value'] = d.id;
              r['text'] = d.name;
              res.push(r);
            });
            this._setDir(data, res);
          }
        });
      if (data == 'leadsStatus')
        this.$axios.get('/mechti/leads_status').then((resp) => {
          let res = [];
          if (resp.data.status === 'ok') {
            let res = resp.data.data;
            res.forEach((d) => {
              d['value'] = d.id;
              d['text'] = d.name;
            });
            this._setDir(data, res);
          }
        });

      if (data == 'office')
        this.$axios.get('/accounting/dir/office/select').then((resp) => {
          if (resp.data.status === 'ok') this._setDir(data, resp.data.data);
        });
      if (data == 'storage')
        this.$axios.get('/accounting/dir/storage/select').then((resp) => {
          if (resp.data.status === 'ok') this._setDir(data, resp.data.data);
        });

      if (data == 'metro')
        this.$axios.get('/mechti/underground_stations/select').then((resp) => {
          if (resp.data.status === 'ok') this._setDir(data, resp.data.data);
        });
      if (data == 'good')
        this.axios.get('/accounting/dir/good/select').then((resp) => {
          if (resp.data.status === 'ok') this._setDir(data, resp.data.data);
        });
      if (data == 'employee')
        this.$axios.get('/accounting/dir/employee/select').then((resp) => {
          if (resp.data.status === 'ok') this._setDir(data, resp.data.data);
        });
      if (data == 'department')
        this.$axios.get('/accounting/dir/department/select').then((resp) => {
          if (resp.data.status === 'ok') this._setDir(data, resp.data.data);
        });

      if (data == 'user') {
        let res;

        await this.$axios.get('/mechti/tasks/users/select').then((resp) => {
          if (resp.data.status === 'ok') {
            res = resp.data.data;
            res.forEach((d) => (d.isActive = d.status == 1));
          }
        });
        res.push({ value: 0, text: ' ' });
        this._setDir(data, res);
      }
      if (data == 'client') {
        this.$axios.get('/mechti/clients/select').then((resp) => {
          if (resp.data.status === 'ok') {
            this._setDir(data, resp.data.data);
          }
        });
      }
      if (data == 'investor') {
        this.$axios.get('/mechti/investors/select').then((resp) => {
          if (resp.data.status === 'ok') {
            this._setDir(data, resp.data.data);
          }
        });
      }
      if (data == 'category')
        this.$axios.get('/accounting/dir/service_category/select').then((resp) => {
          if (resp.data.status === 'ok') {
            this._setDir(data, resp.data.data);
          }
        });
      if (data == 'vendor') {
        this.$axios.get('/accounting/dir/vendor').then((resp) => {
          if (resp.data.status === 'ok') {
            let res = [];
            resp.data.data.forEach((d) => res.push({ id: d.id, value: d.id, text: d.name, document_types: d.document_types }));
            this._setDir(data, res);
          }
        });
      }
      if (data == 'service')
        this.$axios.get('/accounting/dir/service').then((resp) => {
          if (resp.data.status === 'ok') {
            let res = resp.data.data;
            res.forEach((d) => {
              d.value = d.id;
              d.text = d.name;
              d.disabled = d.status !== null && d.status == 2;
            });
            /*  let rr = [];
            res.forEach((r) => {
              rr[r.value] = r;
            });*/
            this._setDir(data, res);
          }
        });
      if (data == 'cashbox') {
        this.$axios.get('/accounting/dir/cashbox/select').then((resp) => {
          let res;
          if (resp.data.status === 'ok') {
            res = resp.data.data;
            res.forEach((d) => (d.isActive = d.status !== 2));
            this._setDir(data, res);
            console.log(data, res);
          }
        });
      }
      if (data == 'object') {
        let res;
        await this.$axios.get('/mechti/objects/select', { params: { filters: { name: { condition: '<>', value: '' } } } }).then((resp) => {
          if (resp.data.status === 'ok') {
            res = resp.data.data;
            res.forEach((o) => (o.isActive = o.status >= 0));
          }
        });
        this._setDir(data, res);
      }
      if (data == 'objectProps')
        this.$axios.get('mechti/object_props/select').then((resp) => {
          if (resp.data.status === 'ok') {
            let res = resp.data.data;
            res.forEach((d) => (d.isActive = d.status !== 2));
            this._setDir(data, res);
          }
        });
    },
    _setDir(n, d) {
      if (!this.dirs_.hasOwnProperty(n)) this.$set(this.dirs_, n, d);
      else {
        this.$set(this.dirs_, n, d);
        //this._dirs[n] = d;
      }
      //this.$set(this.dirs, n, d);
      //this.dirs[n] = d;

      this.dirState_[n]++;
      if (!d.length) {
        console.log(`dir ${n} not items`, d);
        //this.dirState_[n] = 0;
      }
      //if (this.profile.role === 1000) console.log('set dir', n, d);
    },
  },
};

export default dirs;
