<template>
  <v-btn color="primary" @click="$emit('click')" :disabled="disabled" class="px-2" :title="title">
    <v-icon>fas fa-sync-alt</v-icon>
    <btn-title v-if="$vuetify.breakpoint[`${size}AndUp`]">{{ title }}</btn-title>
  </v-btn>
</template>

<script>
export default {
  props: {
    size: { type: String, default: "sm" },
    title: { type: String, default: "Обновить" },
    disabled: { type: Boolean, default: false },
  },
};
</script>
