/** @format */

const documentOrderService = {
  title: 'Заказ услуг',
  accessName: 'orderService',
  accDocName: 'AccountingDocOrderServiceModel',
  api: '/accounting/doc/order_service',
  operations: { 1: [11], 2: [12] },
  operationsCash: { 1: [4, 6, 62], 2: [4, 6] },
  routeName: 'ordersService_view',
  modelName: 'AccountingDocOrderServiceModel',
  orderType: [
    { value: 1, text: 'Заказ на объект' },
    { value: 2, text: 'Заказ на офис' },
  ],
  statuses: [
    { value: 1, text: 'не проведен', next: [2], action: 'Распровести' },
    { value: 2, text: 'проведен', next: [1], action: 'Провести' },
  ],
  list: [
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 30, hidden: true },
    { name: 'code_doc', title: 'Номер', type: 'string', sortable: true, width: 50 },
    { name: 'date_doc', title: 'Дата', type: 'datetime', sortable: true, width: '140' },
    { name: 'type', title: 'Тип', type: 'select', sourceList: 'orderType', sortable: true, width: 150 },
    {
      name: 'order_for',
      title: 'Назначение',
      type: 'string',
      filter: {
        type: 'select',
        dirVar: {
          key: 'type',
          dirName: [
            { value: 1, name: 'object', preText: '[Объект] ', field: 'object_code' },
            { value: 2, name: 'office', preText: '[Офис] ', field: 'object_code' },
          ],
        },
      },
      fieldFilter: ['object_code'],
      isMenu: true,
      sortable: true,
      width: '240',
    },
    { name: 'vendor_id', title: 'Поставщик', type: 'select', dir: 'vendor', width: '250' },
    { name: 'createdon', title: 'Дата', type: 'datetime', sortable: true, width: 150, hidden: true },
    { name: 'createdby_id', title: 'Кем создан', type: 'select', dir: 'user', filter: { type: 'select', name: 'createdby_id', dir: 'user' }, sortable: true, width: 150 },
    { name: 'status', title: 'Статус', type: 'select', sourceList: 'statuses', sortable: true, width: 150 },
    { name: 'total_price', title: 'Сумма', type: 'number', sortable: true, width: 80 },
    {
      name: 'balance',
      title: 'Долг',
      type: 'balanceDoc',
      params: { acc: '60.2', doc_name: 'AccountingDocOrderServiceModel' },
      value: '((d)=>{return d.status==2});',
      sortable: false,
      width: 80,
      hidden: true,
    },
    { name: 'amount_debt', title: 'Долг', type: 'number', sortable: true, width: 80 },
  ],
  formType: [{ name: 'type', title: 'Назначение', type: 'select', sourceList: 'orderType', validator: ['req'] }],
  viewForm1: { fields: 'code_doc,date_doc,createdby_id,operation_type,object_code,store_id,office_id,vendor_id' },
  form: [
    { name: 'date_doc', title: 'Дата документа', type: 'datetime', hiddenCreate: true },
    { name: 'code_doc', title: 'Номер Документа', type: 'string', roleShow: [1000], hiddenCreate: true },
    { name: 'type', title: 'Назначение', type: 'select', sourceList: 'orderType', validator: ['req'] },
    { name: 'operation_type', title: 'Операция', type: 'select', dir: 'operationTable', validator: ['req'] },
    {
      name: 'object_code',
      type: 'selectapi',
      apiVar: {
        key: 'type',
        apiName: [
          { value: 1, name: 'mechti/objects/select', title: 'Объект' },
          { value: 2, name: 'accounting/dir/office/select', title: 'Офис' },
        ],
      },
      isMenu: true,
      validator: ['req'],
    },
    { name: 'vendor_id', title: 'Поставщик', type: 'select', dir: 'vendor', validator: ['req'], autocomplete: true },
    { name: 'status', title: 'Статус', validator: ['req'], default: 1, hidden: true },
    { name: 'createdby_id', title: 'Автор', type: 'select', dir: 'user' },
    { name: 'object', type: 'object', api: 'mechti/objects', fieldId: 'object_code' },
    { name: 'docs', title: 'Документация', type: 'filesdata', category: 1, hiddenCreate: true },
    { name: 'filesAdd', default: [], hidden: true },
  ],
  config: {
    1: {
      note: 'Заказ услуг на объект',
      fields: 'type,date_doc,code_doc,operation_type,object_code,vendor_id,status',
      fieldsRO: '',
      fieldsForm: 'date_doc,type,operation_type,object_code,vendor_id',
      fieldsReplace: { object_code: { name: 'object_code', title: 'Объект', type: 'selectapi', api: 'mechti/objects/select', validator: ['req'] } },
    },
    2: {
      note: 'Заказ услуг на офис',
      fields: 'type,date_doc,code_doc,operation_type,object_code,vendor_id,status',
      fieldsRO: '',
      fieldsForm: 'date_doc,type,operation_type,object_code,vendor_id',
      fieldsReplace: { object_code: { name: 'object_code', title: 'Офис', type: 'selectapi', api: 'accounting/dir/office/select', validator: ['req'] } },
    },
    default: { fields: 'type,status', fieldsRO: '', fieldsForm: 'type' },
  },
  listDetail: [
    { name: 'actions', width: 40 },
    { name: 'id', title: 'id', type: 'number', sortable: true, width: 30 },
    { name: 'category_id', title: 'Категория', type: 'select', dir: 'category', typeEdit1: 'selectapi', validator: ['req'], sortable: true, width: 250 },
    { name: 'service_id', title: 'Наименование', type: 'select', dir: 'service', sortable: true, width: 300 },
    { name: 'price', title: 'Стоимость', type: 'number', sortable: true, width: 100 },
    { name: 'description', title: 'Примечание', type: 'string', sortable: true, width: 300, style: 'white-space: normal;' },
  ],
  formDetail: [
    { name: 'category_id', title: 'Категория', type: 'select', autocomplete: true, dir: 'category', dirFilter: { type: 1 }, validator: ['req'] },
    {
      name: 'service_id',
      title: 'Наименование',
      type: 'select',
      autocomplete: true,
      dir: 'service',
      dirFilter1: { formula: '((d) => {return d.status !== 2;})' },
      validator: ['req'],
    },
    { name: 'description', title: 'Примечание', type: 'text' },
    { name: 'amount', title: 'Кол-во', type: 'number', hidden: true },
    { name: 'price', title: 'Стоимость', type: 'number', validator: ['req'] },
  ],
};
export default documentOrderService;
