/** @format */

export default {
  data() {
    return {
      data: {},
      errors: {},
      warnings: {},
      validators: {},
      oModel: {},
      modelForm: null,
      isChanged: false,
      nestedObject: [],
    };
  },
  created() {
    this.fillForm();
  },
  methods: {
    resetFrom() {
      (this.data = {}), (this.errors = {}), (this.validators = {}), (this.warnings = {});
      this.resetForm();
    },
    resetForm() {},

    getDefaultValue(el) {
      if (this.defaults && this.defaults[el.name] !== undefined) return this.defaults[el.name];
      if (el.default !== undefined) return el.default;
      return null;
    },
    fillForm() {
      if (this.model) this.fillFormFromModel(this.model);
    },

    makeFormulaField(el) {
      let f = el.formula;
      if (!this.data.hasOwnProperty(el.name))
        Object.defineProperty(this.data, el.name, {
          get: function () {
            let res = 0;
            let func;
            if (typeof f == 'function') func = f;
            if (typeof f == 'string') func = eval(f);
            res = func(this);
            return res;
          },
          set: (v) => {},
          enumerable: true,
        });
    },
    fillFormFromModel(model) {
      this.$root.makedirs(model);
      for (let el of model) {
        if (el.json) {
          let name = el.name.split('.')[1];
          let field = el?.jsonField || (el.name.split('.').length > 1 ? el.name.split('.')[0] : 'data');
          if (!this.data?.[field]) {
            //this.data = Object.assign({}, this.data, { data: {} });
            this.$set(this.data, field, {});
          }
          if (!this.data?.[field]?.[el.name]) {
            this.$set(this.data[field], el.name, this.getDefaultValue(el));
            this.$set(this.errors, el.name, null);
            this.$set(this.warnings, el.name, null);
          }
        } else if (el.name.indexOf('.') !== -1) {
          //JSON поле формат записи field.field
          let field = el.name.split('.')[0];
          let name = el.name.split('.')[1];
          if (!this.nestedObject.includes(field)) {
            this.nestedObject = [...this.nestedObject, field];
          }
          if (!this.data?.[field]) {
            //this.data = Object.assign({}, this.data, { data: {} });
            this.$set(this.data, field, {});
          }
          if (!this.data?.[field]?.[name]) {
            this.$set(this.data[field], name, this.getDefaultValue(el));
            this.$set(this.errors, el.name, null);
            this.$set(this.warnings, el.name, null);
          }
        } else if (el?.calculated !== true && !el?.formula) {
          if (!this.data[el.name] && !Object.keys(this.data).includes(el.name)) {
            this.$set(this.data, el.name, this.getDefaultValue(el));
            this.$set(this.errors, el.name, null);
            this.$set(this.warnings, el.name, null);
            if (el.type == 'ref') {
              this.$set(this.data, el.name + '_id', this.getDefaultValue(el.name));
              this.$set(this.errors, el.name + '_id', null);
            }
          } else {
          }
        } else if (el?.formula) {
          this.makeFormulaField(el);
        }
      }
      this.fillFormValidators(model);
      this.makeObjectModel(model);
    },
    fillFormValidators(model, overwrite = true) {
      if (overwrite) this.validators = {};
      for (let el of model) {
        if (el.validator) {
          let name = el.name;
          if (el.type == 'ref') {
            name = name + '_id';
          }
          this.$set(this.validators, name, el.validator);
        }
      }
    },
    arrayModelFromModel(model) {
      let ret = [];
      for (let el in model) {
        let m = model[el];
        m.name = el;
        ret.push(m);
      }
      return ret;
    },
    makeObjectModel(model = this.model) {
      if (model)
        model.forEach((el) => {
          this.oModel[el.name] = el;
        });
    },
    getFormModel(arr, model = this.model) {
      /* let res = this.model.filter((el) => {
        return arr.includes(el.name);
      }); */
      let res = [];
      arr = arr ? arr : [];
      arr.forEach((el) => {
        if (el) {
          let name = el;
          let size;
          if (el.includes('#')) {
            name = el.split('#')[0];
            size = el.split('#')[1];
          }
          let f;
          if (name == 'hr') {
            f = { name: 'hr', type: 'hr' };
          } else {
            f = JSON.parse(
              JSON.stringify(
                model.find((m) => {
                  return m.name == name;
                }) || null
              )
            );
          }
          if (f) {
            if (f?.hidden == true) {
              //например в заказе товаров при создании должно быть скрыто поле статус
            } else f['hidden'] = false;
            if (size) f['size'] = size;
            res = [...res, f];
          }
        }
      });
      if (true)
        model
          .filter((m) => {
            return !res.find((r) => r.name == m.name);
          })
          .forEach((m) => {
            let r = JSON.parse(JSON.stringify(m));
            r['hidden'] = true;
            res.push(r);
          });
      if (res.length) {
        res.forEach((el) => {
          if (!el?.type) {
            el.type = 'string';
          }
        });
      }
      return res;
    },
  },
};
